/* development css ... transfer to app.scss when final */
$primary-color: #8f271e;

.app-page,
.app-page > div {
  position: relative;
}

.app-page-padding {
  padding: 1em;
}

.app-sidebar {
  color: #fff;
}

.app-sidebar [role='button'] {
  padding: 0 2em;
}

.app-sidebar [role='button'] .MuiListItemText-root {
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.6);
  padding: 0.5em 0;
  height: 100%;
}

.cyan-bg {
  background: #00ffff;
}

.app-menu-item-container {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0.1rem;
  box-sizing: border-box;
}

.app-menu-item-container:before {
  content: '';
  display: table;
  padding-top: 100%;
}

.app-menu-item {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-menu-item button {
  width: 100%;
  height: 100%;
  color: #000;
  background: #f7f7f7;
  padding: 0;
  border-radius: 0;
}

.app-menu-item-login {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-menu-item-login button {
  width: 100%;
  height: 100%;
  color: #000;
  background: #f7f7f7;
  padding: 0;
  border-radius: 0;
}

.app-sub-view {
  width: 100%;
}

.app-menu-item button:hover,
.app-menu-item button:focus {
  color: #fff;
  background: $primary-color;
}

// @media only screen and (min-width: 1150px) {
//   .app-menu-item {
//     width: 49vw;
//     height: 49vw;
//   }
// }

.btn-white button {
  color: #000;
  background-color: #fff;
  box-shadow: none;
}

.btn-white button:hover,
.btn-white button:focus {
  color: #fff;
  background: $primary-color;
  box-shadow: none;
}

.app-menu-logo {
  position: absolute;
  top: -40px;
  right: 1em;
}

.app-menu-item button:hover .inner-button object,
.app-menu-item button:focus .inner-button object {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(33deg)
    brightness(107%) contrast(102%);
}

.app-menu-item .MuiButton-label,
.inner-button {
  height: 100%;
}

.inner-content-button object {
  margin-bottom: 0.5em;
  height: 40%;
  pointer-events: none;
}

.app-menu-item button .inner-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-content-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.bite-homeowner-sidebar .MuiListItemText-root span {
  color: #fff;
}

.MuiDrawer-paper {
  background-color: $primary-color !important;
}

.app-sidebar-close {
  cursor: pointer;
  display: inline-block;
  padding: 0.25em;
  position: absolute;
  top: 0.75em;
  left: 0.75em;
}

.app-sidebar-close svg {
  margin: auto;
  vertical-align: top;
}

.app-sidebar-close:hover {
  background-color: rgba($color: #000, $alpha: 0.04);
}

.app-sidebar .bite-homeowner-sidebar {
  margin-top: 3.5em;
}

.carousel-container {
  width: 100vw;
}

@media only screen and (min-width: 1280px) {
  .app-sidebar .bite-homeowner-sidebar {
    margin-top: 0;
  }
  .app-sidebar-close {
    display: none;
  }
  .form-label {
    max-width: 250px;
  }
  .carousel-container {
    max-width: calc(100vw - 240px);
  }
}

@media only screen and (min-width: 768px) {
  .form-label {
    max-width: 350px;
  }
}

.form-label .MuiTypography-root {
  color: $primary-color;
  padding: 0.5em 0 0.25em 0;
  font-weight: 600;
}

.form-label .MuiFormControl-root {
  margin: 0;
}

.bite-label {
  color: $primary-color;
  font-size: 1.3em;
  font-weight: 600;
  padding: 1em 0;
}

.login-form-input {
  margin-left: 1.8em;
}

.login-icons {
  position: absolute;
  top: 12px;
  height: 20px;
  width: 20px;
}

.forgot-password-container p {
  margin-left: auto;
}

.full-width {
  width: 100%;
}

.drawer-dashboard-sidebar .MuiDrawer-paper {
  background-color: transparent !important;
}

.app-dashboard-sidebar {
  background: transparent;
  padding: 0;
  border-right: 1px solid #000;
}

.bite-dashboard-sidebar .MuiList-padding {
  padding-top: 0px;
}

.bite-dashboard-sidebar .MuiListItem-button {
  background: #969696;
  padding-right: 20px;
}

.bite-dashboard-sidebar .MuiCollapse-container .MuiTypography-root {
  padding-left: 1em;
}

.bite-dashboard-sidebar .MuiListItem-button:hover,
.bite-dashboard-sidebar .MuiListItem-button:active {
  background: #8f271e;
}

.bite-dashboard-sidebar .MuiTypography-body1 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.node-container .dashboard-table th {
  color: #939393;
  font-weight: 400;
  font-size: 18px;
}

.node-container .dashboard-table td {
  color: #717171;
  font-weight: 400;
  font-size: 14px;
  $primary-color: #8f271e;
}

.carousel-item {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.carousel-item .inner {
  width: 100%;
  height: 100%;
}

.terms-conditions-list ul,
.terms-conditions-list ul li {
  list-style-type: disc;
}

.terms-conditions-list {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.terms-conditions-list ul {
  margin-left: 1.5em;
}

.btn-homeowner-login button {
  background-color: #74b849;
}

.btn-homeowner-login button:hover {
  background-color: #005081;
}

.txt-homeowner-login .MuiInput-underline {
  border-bottom-color: #005081;
}

.txt-homeowner-login .MuiInput-underline:after {
  border-bottom-color: #74b849;
}

.app-menu-item.login button:hover,
.app-menu-item.login button:focus {
  color: #fff;
  background: #74b849;
}

.app-menus-container {
  width: 100%;
  padding: 1.5em;
  display: flex;
  flex-wrap: wrap;
}

.app-menus-container .row {
  width: 100%;
}

.profile-label {
  color: $primary-color;
  font-weight: 600;
}

.profile-photo-container {
  width: 15em;
  height: 15em;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}

.profile-photo-overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(143, 39, 30, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.profile-photo-container:hover .profile-photo-overlay {
  opacity: 1;
}

.homeowner-sidebar .MuiListItem-root.Mui-selected {
  background-color: rgba(255, 255, 255, 0.4);
}

.dashboard-sidebar .MuiListItem-root.Mui-selected {
  background-color: $primary-color;
}

.homeowner-sidebar .MuiListItem-root:hover,
.homeowner-sidebar .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.bs-dashboard-login .bite,
.bs-homeowner-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.bs-dashboard-login .bite-main,
.bs-homeowner-login-page .bite-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  width: 400px;
}

.login-logo {
  max-width: 300px;
}

.login-upper .row {
  display: flex;
  align-content: center;
  flex-direction: column;
}

.billing-upload-field {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.billing-upload-field {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  height: 300px;
}

.billing-upload-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  height: 380px;
}

.billing-upload-field:hover {
  background: rgba(143, 39, 30, 0.2);
}

.login-comp-logo {
  display: flex;
  justify-content: center;
  flex: 1;
  margin-bottom: 30px;
  flex-direction: column;
}

.upload-required-error {
  border: 1px solid #e53935;
  border-radius: 4px;
}

.bite-dashboard-sidebar .MuiCollapse-container .MuiListItem-button {
  background: #c1645c;
}

.bite-dashboard-sidebar .MuiCollapse-container .MuiListItem-button:hover {
  background: #6b4440;
}

.media-grid-item-container {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0.1rem;
  box-sizing: border-box;
}

.media-grid-item-container:before {
  content: '';
  display: table;
  padding-top: 100%;
}

.media-grid-item {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.7) !important;
  cursor: default !important;
}

.hide {
  display: none;
}

.carousel-container .carousel-container-item {
  cursor: pointer;
}

.carousel-fs-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1201;
  overflow: hidden;
  display: none;
}

.carousel-fs-container.active {
  display: block;
}

.carousel-close-button {
  position: absolute !important;
  top: 0.2em;
  right: 0.2em;
  z-index: 1;
  color: #fff;
}

.carousel-fs-container .carousel-container-item {
  width: 100%;
  height: calc(100% - 48px);
}

.carousel-fs-container .carousel-container-item .carousel-item {
  width: 100%;
  min-height: calc(100vh - 48px);
}

.carousel-fs-container .MuiPaper-root.MuiMobileStepper-root {
  background: none;
  background-color: none;
}

.carousel-fs-container
  .MuiPaper-root.MuiMobileStepper-root
  button
  .MuiButton-label {
  color: #fff;
}

.carousel-fs-container
  .MuiPaper-root.MuiMobileStepper-root
  .MuiMobileStepper-dots
  div:not(.MuiMobileStepper-dotActive) {
  background-color: rgba(255, 255, 255, 0.8);
}

body.unscrollable {
  overflow: hidden;
}

.actionbarButtons {
  display: flex;
  padding: 1em 0;
}

.searchBar {
  display: flex;
  padding: 0.5em 0;
  align-items: center;
}

.in-print {
  display: none;
}

.p-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 2em);
}

.portal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.portal-menu-item-container {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0.1rem;
  box-sizing: border-box;
}

.portal-menu-item-container:before {
  content: '';
  display: table;
  padding-top: 100%;
}

.portal-logout-button {
  margin: 3em 0 1em 0;
}

.portal-logout-button a {
  color: #e53935;
}

.privacy-policy {
  max-width: 960px;
  padding-top: 1.5em;
}

@media (min-width: 992px) {
  .portal-container {
    width: 970px;
  }
}

@media (min-width: 768px) {
  .portal-container {
    width: 750px;
  }

  .portal-menu-item-container {
    max-width: 350px;
    min-width: 350px;
  }

  .privacy-policy {
    padding-top: 5em;
  }
}

@media (min-width: 1200px) {
  .portal-container {
    width: 1170px;
  }
}

.bottom-sidebar {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em 0;
  text-align: center;
}

.bottom-sidebar a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.bottom-sidebar a:hover {
  color: rgb(199, 199, 199);
}
